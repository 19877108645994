@import "../../../assets/scss/variables";

.avatar {
  width: 72px;
  height: 72px;
  text-align: center;
  line-height: 72px;
  background-color: $color-grey;
  border: 1px solid $color-border;
  border-radius: 50%;
  font-weight: 600;

  .profile-photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}